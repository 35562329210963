header {
    padding: 10px 0;
}

.topBar {
    position: relative;
    z-index: 1024;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
//    line-height: 3em;
    justify-content: space-between;
    align-items: center;
}

.topBar-tel, .topBar-social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .topBar-link {
        color: $colorText;
        &:hover {
            color:$primary;
        }
        &:focus, &:active {
            color: $primaryLight;
        }
    }
    button.topBar-link{
        border: 0;
        background: transparent;
    }
     
    @include respond-to($mobile){
        display:none; 
    }
    @include respond-to($tablet-portrait){
        width: 60px; 
    }@include respond-to($tablet-landscape){
        width: 60px; 
    }@include respond-to($large-desktop){
        width: 60px; 
    }@include respond-to($wide-desktop){
        width: 80px; 
    }
}

#logo {
    margin: 0 auto;
    transition: $transition;
    position: relative;
    z-index: 1;
    img {
        max-width: 100%;
        height: auto;
    }
    
    @include respond-to($phone-portrait){
        margin: 0;
        display: flex;
        justify-content: flex-end;
        padding-right: 3%;
        a {
            width: 70%;
        }
    }@include respond-to($phone-landscape){
        width: 70%;    
    }@media (min-width: 768px)and(max-width: 1439px){
        width: 50%;
    }
    @media (min-width: 1440px) {
        width: 688px;
    }
}

.home {
    header {
        background: url(/images/le-bistrot-dangel_resto-pizzeria-echoppe.jpg) no-repeat top center transparent;
        background-size: cover;
        position: relative;
        transition: $transition;
        &:before {
            display: block;
            width: 100%;
            height: 100%;
            content: "";
            background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 51.44%);
            position: absolute;
            top: 0;
            left: 0;
        }
        @include respond-to($phone-portrait){
            height: 400px;
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
        }@include respond-to($phone-landscape){
            height: 500px;
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
        }@include respond-to($tablet-portrait){
            height: 600px;
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
        }@include respond-to($tablet-landscape){
            height: 700px;
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
        }@include respond-to($semi-desktop1){
            height: 800px;
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
        }@include respond-to($semi-desktop2){
            height: 1000px;
            clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
        }@include respond-to($wide-desktop){
            height: 1200px;
            clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
        }
    }
}