.lang {
  margin-left: -15px;
  margin-right: -15px;

  &-item {
    padding-left: 15px;
    padding-right: 15px;

    &--active {
        font-weight: 700;
    }
  }
}
