footer {
    padding: 0 0 20px;
    
    @include respond-to($wide-desktop){
        .container {
            width: 1170px;
        }
    }
    
    a {
        color: $colorText;
        &:hover {
            color:$primary;
        }
        &:focus, &:active {
            color: $primaryLight;
        }   
    }
    
    .footer-logo {
        text-align: center;
        margin: 0 auto 26px;
        position: relative;
        @include respond-to($mobile){
            .img-fluid {
                height: 100px;
            }
        }@include respond-to($tablet-portrait){
            .img-fluid {
                height: 120px;
            }
        }@include respond-to($tablet-landscape){
            .img-fluid {
                height: 120px;
            }
        }@include respond-to($semi-desktop1){
            .img-fluid {
                height: 120px;
            }
        }@include respond-to($semi-desktop2){
            .img-fluid {
                height: 192px;
            }
        }@include respond-to($wide-desktop){
            .img-fluid {
                height: 192px;
            }
        }
    }
    
    .footer-infos {
        justify-content: space-between;
        align-items: top;
        @include respond-to($phone-portrait){
            justify-content: center;
            .footer-contact,
            .footer-resa,
            .footer-social {
                flex-basis: 100%;
            }
        }@include respond-to($phone-landscape){
            justify-content: center;
            .footer-contact,
            .footer-resa,
            .footer-social {
                flex-basis: 50%;
            }
        }@include respond-to($tablet-portrait){
            justify-content: center;
            .footer-contact,
            .footer-resa,
            .footer-social {
                flex-basis: 50%;
            }
        }@include respond-to($tablet-landscape){
            justify-content: center;
            .footer-contact,
            .footer-resa,
            .footer-social {
                flex-basis: 50%;
            }
        }@include respond-to($semi-desktop1){
            .footer-contact,
            .footer-resa,
            .footer-social {
                flex-basis: 30%;
            }
        }@include respond-to($semi-desktop2){
            .footer-contact,
            .footer-resa,
            .footer-social {
                flex-basis: 27%;
            }
        }@include respond-to($wide-desktop){
            .footer-contact,
            .footer-resa,
            .footer-social {
                flex-basis: 27%;
            }
        }
        .footer-resa {
            a {
                white-space: nowrap;
            }
            @media(min-width: 1200px){
                text-align: center;
            }
        }
        .rs-list {
            display: flex;
            justify-content: space-around;
            font-size: 150%;
        }
    }  
}

.footer-horaires {
    margin-top: 3rem;
    justify-content: space-between;
    
    .horaires-resto {
        flex-basis: 50%;
        h2 {
            height: 54px;
            text-indent: -9999px;
            background: url(/images/svg/logo-resto-pizzeria.svg) no-repeat center left transparent;
            background-size: contain;
        }
        @include respond-to($mobile){
            flex-basis: 100%;
        }
    }
    .horaires-echoppe {
        h2 {
            height: 40px;
            text-indent: -9999px;
            background: url(/images/logo-l-echoppe.png) no-repeat center left transparent;
            background-size: contain;
        }
        @include respond-to($mobile){
            flex-basis: 100%;
        }
    }
}

#footer-navigation {
    .footer-navigationList {
        list-style: none;
        margin: 1.5rem 0;
        font-size: rem(18px);
        li {
            display: inline;
        }
    }
}

.footer-signature {
    font-size: rem(14px);
    a {
        color: $colorText;   
        &:hover {
            color:$primary;
        }
        &:focus, &:active {
            color: $primaryLight;
        }
    }
    @include respond-to($phone-portrait){
        .text-right {
            text-align: left !important;
        }
    }
}


/* BOTTOM BAR MOBILE */
.bottomBar {
    background: $body-bg;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    width: 100%;
    //height: 34px;
    justify-content: space-between;
    @include respond-to($mobile){
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    .topBar-link {
        border: 0;
        background: transparent;
        color: $primary;
        font-size: 150%;
    }
}