@charset "UTF-8";
@import '~bootstrap/dist/css/bootstrap.css';
@import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import '~jquery-ui/themes/base/all.css';
/* img-responsive */
#page img {
  max-width: 100%;
  height: auto;
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/fonts/roboto-v29-latin-regular.woff2") format("woff2"), url("/fonts/roboto-v29-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local(""), url("/fonts/roboto-v29-latin-italic.woff2") format("woff2"), url("/fonts/roboto-v29-latin-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("/fonts/roboto-v29-latin-700.woff2") format("woff2"), url("/fonts/roboto-v29-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("/fonts/roboto-v29-latin-700italic.woff2") format("woff2"), url("/fonts/roboto-v29-latin-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* old-standard-tt-regular - latin */
@font-face {
  font-family: "Old Standard TT";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/fonts/old-standard-tt-v17-latin-regular.woff2") format("woff2"), url("/fonts/old-standard-tt-v17-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* old-standard-tt-700 - latin */
@font-face {
  font-family: "Old Standard TT";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("/fonts/old-standard-tt-v17-latin-700.woff2") format("woff2"), url("/fonts/old-standard-tt-v17-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon.eot?sa7rsq");
  src: url("/fonts/icomoon.eot?sa7rsq#iefix") format("embedded-opentype"), url("/fonts/icomoon.ttf?sa7rsq") format("truetype"), url("/fonts/icomoon.woff?sa7rsq") format("woff"), url("/fonts/icomoon.svg?sa7rsq#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-beer:before {
  content: "";
}

.icon-coffee-shop-restaurant:before {
  content: "";
}

.icon-pizza:before {
  content: "";
}

.icon-tripadvisor:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
@media (max-width: 991px) {
  html {
    font-size: 0.8rem;
  }
}

body {
  margin: 0;
  padding: 0;
  text-align: left;
  background: #000;
  font-family: "Roboto", Arial, sans-serif;
  color: white;
  font-size: 1.5rem;
  --swiper-theme-color: #A93B2A;
  --swiper-navigation-size: 1.5rem;
  --swiper-theme-color: #A93B2A;
  --swiper-pagination-bullet-inactive-color: #16172B;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 6rem;
  --swiper-pagination-bullet-height: 6px;
  --swiper-pagination-bullet-horizontal-gap: .6rem;
}
@media only screen and (max-width: 767px) {
  body {
    --swiper-pagination-bullet-width: 4rem;
  }
}

.hidden {
  display: none;
}

@media (min-width: 1440px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1440px;
  }
}
h1, h2, h3, h4, h5, h6, .title, .subtitle {
  margin: 1.25rem 0;
  padding: 0;
  font-weight: 700;
  font-family: "Old Standard TT", Arial, sans-serif;
  color: white;
}

h1 {
  font-size: 3.125rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.title {
  font-size: 3.125rem;
}

.subtitle {
  font-size: 1.5rem;
}

/*input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="week"],
input[type="month"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
optgroup,
select,
textarea,
.form-control {
  border: 1px solid gainsboro;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  background-color: #fff;
}

.form-control:focus {
    border-color: rgba($primary, .25);
    box-shadow: 0 0 0 0.2rem rgba($primary, .25);
}*/
ul, ol {
  margin: 10px;
  padding: 0;
  list-style-position: inside;
}

a {
  transition: all, 0.5s, ease-out 0.5s;
  color: #A93B2A;
}
a:hover {
  text-decoration: none;
  color: #A93B2A;
}
a:focus {
  outline: 0;
}
a img {
  border: 0;
}

blockquote {
  margin: 30px 0;
  padding: 5px 15px;
  border-left: 4px solid #eee;
  font-style: italic;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

button {
  transition: all, 0.5s, ease-out 0.5s;
}

.overflow {
  overflow: hidden;
}

.scrollToTop {
  position: fixed;
  bottom: 50px;
  right: 5px;
  cursor: pointer;
  z-index: 1000000;
  display: none;
}
.scrollToTop-icon {
  fill: #A93B2A;
  width: 25px;
  height: 25px;
}
@media (min-width: 768px) {
  .scrollToTop {
    bottom: 20px;
    right: 20px;
  }
  .scrollToTop-icon {
    width: 35px;
    height: 35px;
  }
}

ol.breadcrumb {
  margin: 20px 0;
  padding: 0;
  background: transparent;
  font-size: 0.9375rem;
}

/*
 * Toggler menu mobile */
.toggler-mobile {
  position: absolute;
  z-index: 1024;
  width: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .toggler-mobile {
    top: -20px;
    left: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .toggler-mobile {
    top: -20px;
    left: 50%;
    margin-left: -25px;
  }
}
@media (min-width: 992px) {
  .toggler-mobile {
    display: none;
  }
}
.toggler-mobile .line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.toggler-mobile .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.toggler-mobile .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.toggler-mobile .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.toggler-mobile.opened {
  left: inherit;
  right: 10px;
}
.toggler-mobile.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.toggler-mobile.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.toggler-mobile.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

/* #CONTENT
================================================== */
.bg__lines {
  background: url(/images/svg/lines.svg) no-repeat top center transparent;
  background-size: cover;
}

.card-intro {
  position: relative;
  border-radius: 8px;
  padding: 2rem;
  overflow: hidden;
}
.card-intro:before {
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(180deg, rgba(169, 59, 42, 0.5) 0%, #16172B 100%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.card-intro .card-intro-content {
  position: relative;
  z-index: 1;
  font-size: 1.25rem;
}

.home .card-intro:before {
  background: linear-gradient(0deg, rgba(169, 59, 42, 0.2) 30%, #16172B 100%);
}

#content {
  padding-bottom: 20px;
}

.home #content {
  padding-bottom: 0;
}

#horaires {
  color: #000;
}
#horaires p {
  font-size: 1.25rem;
}
#horaires h1, #horaires h2, #horaires h3, #horaires h4, #horaires h5, #horaires h6 {
  color: #000;
  font-size: 1.875rem;
}

.fancybox-slide--current #hidden-content-b {
  transform: translateY(0);
}

/* ----- NOTIFICATIONS -----*/
.result_error {
  color: #fff;
  line-height: 40px;
  text-align: center;
  background: #e57c7c;
}

.result_success {
  color: #fff;
  line-height: 40px;
  text-align: center;
  background: #78ca71;
}

#flashMessage {
  background: #fff;
  color: #337ab7;
  line-height: 40px;
  text-align: center;
}

#tableau_login {
  overflow: hidden;
}

#compte {
  margin: 20px auto;
}

#UserSiteLoginForm div, #UserAddUserForm div {
  margin: 0 0 10px;
}

.error-message {
  color: #a94442;
}

.accept {
  display: inline;
}

.control-label label {
  font-family: "Old Standard TT", Arial, sans-serif;
}

.error .form-control {
  border-color: #e3342f;
}
.error .control-label {
  color: #e3342f;
}

.mandatory {
  height: 0.1px;
  width: 0;
  border: 0 !important;
  padding: 0 !important;
}

.mentionRGPD, .champsRequis {
  font-size: 0.875rem;
}

#map {
  background: #e9ecef;
  max-width: 100%;
  height: 500px;
  color: #000;
}
#map .googlemaps-canvas {
  max-width: 100%;
}

.form-control {
  border-radius: 8px;
  background: transparent;
  color: white;
}
.form-control:focus {
  border-color: #d87161;
  box-shadow: 0 0 0 0.2rem rgba(159, 69, 42, 0.5);
}

/* ----- DEMO ASSETS ----- */
.bs-component .modal {
  position: relative;
  top: auto;
  right: auto;
  left: auto;
  bottom: auto;
  z-index: 1;
  display: block;
}
.bs-component .modal-dialog {
  width: 90%;
}

.modal-title {
  margin-top: 0;
}

.pagination {
  justify-content: center;
}

/*.page {
    &-item {
        &.active {
            background-color: $primary;
            .page-link {
                background-color: $primary;
                border-color: $primary;
            }
        }
    }
    &-link {
        &, &:hover {
            color: $primary;
        }
        &:focus {
            box-shadow: none;
        }
    }
}*/
.btn {
  font-family: "Old Standard TT", Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  white-space: normal;
  transition: all, 0.5s, ease-out 0.5s;
}
.btn:hover, .btn:active, .btn.active {
  transition: all, 0.5s, ease-out 0.5s;
}
.btn:focus, .btn:active:focus, .btn.active:focus, .btn:active.focus, .btn.active.focus {
  outline: 0;
  transition: all, 0.5s, ease-out 0.5s;
}

.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: none;
}

.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  box-shadow: none;
}

.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
  text-shadow: none;
}

.btn-link, .btn-primary {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed white;
  color: white;
  box-shadow: none;
}
.btn-link:hover, .btn-link:focus, .btn-primary:hover, .btn-primary:focus {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #A93B2A;
  color: #A93B2A;
  box-shadow: none;
}
.btn-link:active, .btn-link.active, .btn-primary:active, .btn-primary.active {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #571e16;
  color: #571e16;
  box-shadow: none;
}
.btn-link.disabled, .btn-link:disabled, .btn-link[disabled], .btn-primary.disabled, .btn-primary:disabled, .btn-primary[disabled] {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #d87161;
  color: #d87161;
}
.btn-link:not(:disabled):not(.disabled):active, .btn-link:not(:disabled):not(.disabled).active, .show > .btn-link.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background: transparent;
  border-bottom: 1px dashed #571e16;
  box-shadow: none;
}
.btn-link:not(:disabled):not(.disabled):active:focus, .btn-link:not(:disabled):not(.disabled).active:focus, .show > .btn-link.dropdown-toggle:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed white;
  color: white;
  box-shadow: none;
}
.btn-secondary:hover, .btn-secondary:focus {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #16172B;
  color: #16172B;
  box-shadow: none;
}
.btn-secondary:active, .btn-secondary.active {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed black;
  color: black;
  box-shadow: none;
}
.btn-secondary.disabled, .btn-secondary:disabled, .btn-secondary[disabled] {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #393b6e;
  color: #393b6e;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #393b6e;
  background: transparent;
  border-bottom: 1px dashed black;
  box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #38c172;
  color: #38c172;
  box-shadow: none;
}
.btn-success:hover, .btn-success:focus {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #38c172;
  color: #38c172;
  box-shadow: none;
}
.btn-success:active, .btn-success.active {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #38c172;
  color: #38c172;
  box-shadow: none;
}
.btn-success.disabled, .btn-success:disabled, .btn-success[disabled] {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #393b6e;
  color: #393b6e;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #393b6e;
  background: transparent;
  border-bottom: 1px dashed black;
  box-shadow: none;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-info {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #3490dc;
  color: #3490dc;
  box-shadow: none;
}
.btn-info:hover, .btn-info:focus {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #3490dc;
  color: #3490dc;
  box-shadow: none;
}
.btn-info:active, .btn-info.active {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #3490dc;
  color: #3490dc;
  box-shadow: none;
}
.btn-info.disabled, .btn-info:disabled, .btn-info[disabled] {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #393b6e;
  color: #393b6e;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #393b6e;
  background: transparent;
  border-bottom: 1px dashed black;
  box-shadow: none;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-warning {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #f6993f;
  color: #f6993f;
  box-shadow: none;
}
.btn-warning:hover, .btn-warning:focus {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #f6993f;
  color: #f6993f;
  box-shadow: none;
}
.btn-warning:active, .btn-warning.active {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #f6993f;
  color: #f6993f;
  box-shadow: none;
}
.btn-warning.disabled, .btn-warning:disabled, .btn-warning[disabled] {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #393b6e;
  color: #393b6e;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #393b6e;
  background: transparent;
  border-bottom: 1px dashed black;
  box-shadow: none;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-danger {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #e3342f;
  color: #e3342f;
  box-shadow: none;
}
.btn-danger:hover, .btn-danger:focus {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #e3342f;
  color: #e3342f;
  box-shadow: none;
}
.btn-danger:active, .btn-danger.active {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #e3342f;
  color: #e3342f;
  box-shadow: none;
}
.btn-danger.disabled, .btn-danger:disabled, .btn-danger[disabled] {
  background: transparent;
  border: 0;
  border-bottom: 1px dashed #393b6e;
  color: #393b6e;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #393b6e;
  background: transparent;
  border-bottom: 1px dashed black;
  box-shadow: none;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.lang {
  margin-left: -15px;
  margin-right: -15px;
}
.lang-item {
  padding-left: 15px;
  padding-right: 15px;
}
.lang-item--active {
  font-weight: 700;
}

div#tarteaucitronAlertBig:focus {
  outline: 0;
}

.tarteaucitron-modal-open {
  overflow: hidden;
  height: 100%;
}

#tarteaucitronContentWrapper {
  display: unset;
}

/** 14042021 **/
span.tarteaucitronReadmoreSeparator {
  display: inline !important;
}

/******/
/** 09052021 **/
.tarteaucitronName .tacCurrentStatus, .tarteaucitronName .tarteaucitronReadmoreSeparator {
  color: #333 !important;
  font-size: 12px !important;
  text-transform: capitalize;
}

/**************/
/** 27032021 **/
button.tarteaucitron-toggle-group {
  display: block;
}

span.tarteaucitronH3 {
  font-weight: 700 !important;
}

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  font-weight: 500 !important;
  font-size: 14px;
  margin-top: 7px;
}

.tarteaucitronLine {
  border-left: 0px solid transparent !important;
}

/*****/
/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {
  html body #tarteaucitronRoot #tarteaucitron ul#tarteaucitronServices_mandatory .tarteaucitronDeny {
    display: none !important;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button,
html body #tarteaucitronRoot #tarteaucitron .tarteaucitronAsk,
html body #tarteaucitronRoot #tarteaucitron .tarteaucitronName {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
    margin-bottom: 8px !important;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
    padding: 16px !important;
  }

  html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
    display: none !important;
  }

  #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
    width: 100% !important;
    display: inline-block;
  }

  li.tarteaucitronLine .tarteaucitronName span {
    width: 80% !important;
    display: inline-block;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
    width: 10% !important;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0px;
    padding: 10px 0;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:before {
    content: "▾";
    font-weight: 700;
    font-size: 14px;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:before {
    content: "▴";
  }
}
@media screen and (min-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:after {
    content: "▾";
    font-weight: 700;
    font-size: 14px;
    margin-left: 15px;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:after {
    content: "▴";
    margin-left: 15px;
  }
}
/****/
/***
* Reset CSS
*/
#tarteaucitronRoot div, #tarteaucitronRoot span, #tarteaucitronRoot applet, #tarteaucitronRoot object, #tarteaucitronRoot iframe, #tarteaucitronRoot h1, #tarteaucitronRoot h2, #tarteaucitronRoot h3, #tarteaucitronRoot h4, #tarteaucitronRoot h5, #tarteaucitronRoot h6, #tarteaucitronRoot p, #tarteaucitronRoot blockquote, #tarteaucitronRoot pre, #tarteaucitronRoot a, #tarteaucitronRoot abbr, #tarteaucitronRoot acronym, #tarteaucitronRoot address, #tarteaucitronRoot big, #tarteaucitronRoot cite, #tarteaucitronRoot code, #tarteaucitronRoot del, #tarteaucitronRoot dfn, #tarteaucitronRoot em, #tarteaucitronRoot img, #tarteaucitronRoot ins, #tarteaucitronRoot kbd, #tarteaucitronRoot q, #tarteaucitronRoot s, #tarteaucitronRoot samp, #tarteaucitronRoot small, #tarteaucitronRoot strike, #tarteaucitronRoot strong, #tarteaucitronRoot sub, #tarteaucitronRoot sup, #tarteaucitronRoot tt, #tarteaucitronRoot var, #tarteaucitronRoot b, #tarteaucitronRoot u, #tarteaucitronRoot i, #tarteaucitronRoot center, #tarteaucitronRoot dl, #tarteaucitronRoot dt, #tarteaucitronRoot dd, #tarteaucitronRoot ol, #tarteaucitronRoot ul, #tarteaucitronRoot li, #tarteaucitronRoot fieldset, #tarteaucitronRoot form, #tarteaucitronRoot label, #tarteaucitronRoot legend, #tarteaucitronRoot table, #tarteaucitronRoot caption, #tarteaucitronRoot tbody, #tarteaucitronRoot tfoot, #tarteaucitronRoot thead, #tarteaucitronRoot tr, #tarteaucitronRoot th, #tarteaucitronRoot td, #tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot canvas, #tarteaucitronRoot details, #tarteaucitronRoot embed, #tarteaucitronRoot figure, #tarteaucitronRoot figcaption, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot output, #tarteaucitronRoot ruby, #tarteaucitronRoot section, #tarteaucitronRoot summary, #tarteaucitronRoot time, #tarteaucitronRoot mark, #tarteaucitronRoot audio, #tarteaucitronRoot video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /*background: initial;*/
  text-align: initial;
  text-shadow: initial;
}

/* Animation */
#tarteaucitronRoot * {
  transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot details, #tarteaucitronRoot figcaption, #tarteaucitronRoot figure, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot section {
  display: block;
}

#tarteaucitronRoot ol, #tarteaucitronRoot ul {
  list-style: none;
}

#tarteaucitronRoot blockquote, #tarteaucitronRoot q {
  quotes: none;
}

#tarteaucitronRoot blockquote:before, #tarteaucitronRoot blockquote:after, #tarteaucitronRoot q:before, #tarteaucitronRoot q:after {
  content: "";
  content: none;
}

#tarteaucitronRoot table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible, #tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8;
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}

div#tarteaucitronServices {
  margin-top: 21px !important;
}

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0px solid slategrey;
}

div#tarteaucitronServices {
  box-shadow: 0 40px 60px #545454;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important;
  }

  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px;
  }
}
@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer, #tarteaucitron {
    background: #fff;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important;
  }

  #tarteaucitron .tarteaucitronBorder {
    border: 0 !important;
  }

  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0 !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important;
  }

  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important;
  }
}
/***
 * Common value
 */
#tarteaucitronRoot div#tarteaucitron {
  left: 0;
  right: 0;
  margin: auto;
}

#tarteaucitronRoot button#tarteaucitronBack {
  background: #eee;
}

#tarteaucitron .clear {
  clear: both;
}

#tarteaucitron a {
  color: #424242;
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
}

#tarteaucitronRoot button {
  background: transparent;
  border: 0;
}

#tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
  color: #fff;
}

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500;
}

#tarteaucitron ul {
  padding: 0;
}

#tarteaucitron .tarteaucitronH1, #tarteaucitron .tarteaucitronH2, #tarteaucitron .tarteaucitronH3, #tarteaucitron .tarteaucitronH4, #tarteaucitron .tarteaucitronH5, #tarteaucitron .tarteaucitronH6 {
  display: block;
}

.cookie-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/***
 * Root div added just before </body>
 */
#tarteaucitronRoot {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

div#tarteaucitronRoot.tarteaucitronBeforeVisible::before {
  background: #222 !important;
}

#tarteaucitronRoot * {
  box-sizing: initial;
  color: #333;
  font-family: sans-serif !important;
  font-size: 14px;
  line-height: normal;
  vertical-align: initial;
}

#tarteaucitronRoot .tarteaucitronH1 {
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  margin: 15px 0 28px;
}

#tarteaucitronRoot .tarteaucitronH2 {
  display: inline-block;
  margin: 12px 0 0 10px;
  color: #fff;
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0;
}

/***
 * Control panel
 */
#tarteaucitronBack {
  background: #fff;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}

#tarteaucitron {
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647;
}

#tarteaucitron .tarteaucitronBorder {
  background: #fff;
  border: 2px solid #333;
  border-top: 0;
  height: auto;
  overflow: auto;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
  background: #333333;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  padding: 4px 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70px;
}

#tarteaucitron #tarteaucitronDisclaimer {
  color: #555;
  font-size: 12px;
  margin: 15px auto 0;
  width: 80%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  background: rgba(51, 51, 51, 0.07);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
  width: 100%;
  box-sizing: border-box;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0px 0px;
  padding: 5px 20px;
  text-align: left;
  width: auto;
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  color: #fff;
  font-weight: 500;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
  text-decoration: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
  font-size: 22px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  font-size: 14px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
  padding: 5px 10px;
  margin: 0;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: none;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  max-width: 270px;
  padding: 20px;
  position: absolute;
  z-index: 2147483647;
}

#tarteaucitron #tarteaucitronInfo a {
  color: #fff;
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  background: rgba(51, 51, 51, 0.1);
  border-left: 5px solid transparent;
  margin: 0;
  overflow: hidden;
  padding: 15px 5px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
  border-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
  border-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background: #333;
  border: 3px solid #333;
  border-left: 9px solid #333;
  border-top: 5px solid #333;
  margin-bottom: 0;
  margin-top: 21px;
  position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
  margin-left: 15px;
  margin-top: 2px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
  color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
  margin-top: 0px !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: left;
  width: 50%;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow {
  background: gray;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  width: auto;
  border: 0;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
  background-color: #1B870B;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
  background-color: #9C1A1A;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
  background-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
  background-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
  color: #333;
  font-size: 12px;
}

#tarteaucitron .tarteaucitronH3 {
  font-size: 18px;
}

#tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
  width: auto !important;
  margin-left: 0 !important;
  font-size: 14px;
}

span#tarteaucitronDisclaimerAlert {
  padding: 0 10px;
  display: inline-block;
}

#tarteaucitron .tarteaucitronBorder, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  border-color: #333 !important;
}

/***
 * Big alert
 */
.tarteaucitronAlertBigTop {
  top: 0;
}

.tarteaucitronAlertBigBottom {
  bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  background: #fff;
  color: #222;
  display: none;
  font-size: 15px !important;
  position: fixed;
  box-sizing: content-box;
  z-index: 2147483645;
  text-align: center;
  padding: 40px 0 20px;
  margin: auto;
  box-shadow: 0 0 4px silver;
  border-radius: 0 !important;
  max-width: 250px !important;
  width: calc(100% - 16px) !important;
  min-width: 0 !important;
  bottom: 8px;
  top: auto !important;
  left: 8px;
  right: auto !important;
}
@media (max-width: 767px) {
  #tarteaucitronRoot #tarteaucitronAlertBig {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font: 15px verdana;
  color: #222;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert {
  margin: 30px 0 !important;
  text-align: left !important;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font-weight: 700;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog {
  cursor: pointer;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
  background: #008300;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px !important;
  line-height: 1.2;
  padding: 5px 11px;
  text-decoration: none;
  margin-left: 7px;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  font-size: 14px !important;
}

#tarteaucitronRoot .tarteaucitronDeny {
  background: transparent;
}

#tarteaucitronAlertBig #tarteaucitronAllDenied2 {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #222;
  margin-left: 0;
  text-align: left;
  font-size: 14px !important;
  padding: 0;
}
#tarteaucitronAlertBig #tarteaucitronAllDenied2 .tarteaucitronCross {
  display: none;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog, #tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  background: #fff;
  color: #222;
  margin-bottom: 3px;
  margin-left: 7px;
  padding: 5px 10px;
  border: 1px solid #222;
}

#tarteaucitronPercentage {
  background: #0A0 !important;
  box-shadow: 0 0 2px #fff, 0 1px 2px #555;
  height: 5px;
  left: 0;
  position: fixed;
  width: 0;
  z-index: 2147483644;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronAllow,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}

/***
 * Icon
 */
.tarteaucitronIconBottomRight {
  bottom: 0;
  right: 0;
}

.tarteaucitronIconBottomLeft {
  bottom: 0;
  left: 0;
}

.tarteaucitronIconTopRight {
  top: 0;
  right: 0;
}

.tarteaucitronIconTopLeft {
  top: 0;
  left: 0;
}

.tarteaucitronIconTopLeft #tarteaucitronManager {
  border-radius: 2px 7px 7px 2px;
}

.tarteaucitronIconTopRight #tarteaucitronManager {
  border-radius: 7px 2px 2px 7px;
}

.tarteaucitronIconBottomLeft #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

.tarteaucitronIconBottomRight #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

#tarteaucitronIcon {
  background: transparent;
  position: fixed;
  display: none;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronIcon #tarteaucitronManager {
  color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
  border: none;
}

#tarteaucitronIcon #tarteaucitronManager img {
  width: 50px;
  height: 50px;
}

#tarteaucitronRoot .tarteaucitronCross::before {
  content: "✗";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronCheck::before {
  content: "✓";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronPlus::before {
  content: "✛";
  display: inline-block;
  color: white;
}

/***
 * Small alert
 */
.tarteaucitronAlertSmallTop, .tarteaucitronAlertSmallBottom {
  bottom: 0;
}

#tarteaucitronAlertSmall {
  background: #333;
  display: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronAlertSmall #tarteaucitronManager {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
}

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
  background: rgba(255, 255, 255, 0.05);
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
  background-color: gray;
  border-radius: 5px;
  display: block;
  height: 8px;
  margin-bottom: 1px;
  margin-top: 5px;
  overflow: hidden;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  display: block;
  float: left;
  height: 100%;
  width: 0%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
  background-color: #1B870B;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
  background-color: #FBDA26;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  background-color: #9C1A1A;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  padding: 0px 10px;
  vertical-align: top;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
  background: rgba(255, 255, 255, 0.3);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
  display: none;
  max-height: 70%;
  max-width: 500px;
  position: fixed;
  right: 0;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
  background: #fff;
  border: 2px solid #333;
  color: #333;
  font-size: 11px;
  height: auto;
  overflow: auto;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList strong {
  color: #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
  background: #333;
  margin-top: 21px;
  padding: 13px 0 9px 13px;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle strong {
  color: #fff;
  font-size: 16px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
  background: rgba(51, 51, 51, 0.1);
  padding: 7px 5px 10px;
  word-wrap: break-word;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain a {
  color: #333;
  text-decoration: none;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft {
  display: inline-block;
  width: 50%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft a strong {
  color: darkred;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListRight {
  color: #333;
  display: inline-block;
  font-size: 11px;
  margin-left: 10%;
  vertical-align: top;
  width: 30%;
}

/***
 * Fallback activate link
 */
.tac_activate {
  background: #333;
  color: #fff;
  display: table;
  font-size: 12px;
  height: 100%;
  line-height: initial;
  margin: auto;
  text-align: center;
  width: 100%;
}

.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.tac_activate .tac_float strong {
  color: #fff;
}

.tac_activate .tac_float .tarteaucitronAllow {
  background-color: #1B870B;
  display: inline-block;
}

/***
 * CSS for services
 */
ins.ferank-publicite, ins.adsbygoogle {
  text-decoration: none;
}

div.amazon_product {
  height: 240px;
  width: 120px;
}

.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important;
}

.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important;
}

.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important;
}

.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important;
}

.tarteaucitronLine .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronDeny {
  opacity: 0.4;
}

#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1;
}

div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  text-align: center !important;
  max-width: 80% !important;
  padding: 15px 0 !important;
  margin: -10px auto 40px !important;
  font-size: 1em !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #555;
}

a.tarteaucitronSelfLink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center !important;
  display: block;
  height: 30px;
}

.tarteaucitronMainLine .tarteaucitronH2 {
  font-size: 1.2em !important;
  margin-top: 4px !important;
}

span.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px !important;
}

#tarteaucitronCloseCross {
  position: absolute;
  color: #222;
  font-size: 1.8rem;
  cursor: pointer;
  top: -6px;
  right: 20px;
  text-indent: -99999px;
}
#tarteaucitronCloseCross:after {
  display: block;
  content: "";
  width: 16px;
  height: 16px;
  background: url(/svg/Close.svg) no-repeat transparent;
  background-size: contain;
}

.tarteaucitron-spacer-20 {
  height: 20px;
  display: block;
}

.tarteaucitron-display-block {
  display: block;
}

.tarteaucitron-display-none {
  display: none;
}

div#tarteaucitronAlertBig::before {
  display: none;
}

#tac_title {
  display: none;
}

header {
  padding: 10px 0;
}

.topBar {
  position: relative;
  z-index: 1024;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.topBar-tel, .topBar-social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.topBar-tel .topBar-link, .topBar-social .topBar-link {
  color: white;
}
.topBar-tel .topBar-link:hover, .topBar-social .topBar-link:hover {
  color: #A93B2A;
}
.topBar-tel .topBar-link:focus, .topBar-tel .topBar-link:active, .topBar-social .topBar-link:focus, .topBar-social .topBar-link:active {
  color: #d87161;
}
.topBar-tel button.topBar-link, .topBar-social button.topBar-link {
  border: 0;
  background: transparent;
}
@media only screen and (max-width: 767px) {
  .topBar-tel, .topBar-social {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .topBar-tel, .topBar-social {
    width: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .topBar-tel, .topBar-social {
    width: 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .topBar-tel, .topBar-social {
    width: 60px;
  }
}
@media only screen and (min-width: 1920px) {
  .topBar-tel, .topBar-social {
    width: 80px;
  }
}

#logo {
  margin: 0 auto;
  transition: all, 0.5s, ease-out 0.5s;
  position: relative;
  z-index: 1;
}
#logo img {
  max-width: 100%;
  height: auto;
}
@media only screen and (max-width: 575px) {
  #logo {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 3%;
  }
  #logo a {
    width: 70%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  #logo {
    width: 70%;
  }
}
@media (min-width: 768px) and (max-width: 1439px) {
  #logo {
    width: 50%;
  }
}
@media (min-width: 1440px) {
  #logo {
    width: 688px;
  }
}

.home header {
  background: url(/images/le-bistrot-dangel_resto-pizzeria-echoppe.jpg) no-repeat top center transparent;
  background-size: cover;
  position: relative;
  transition: all, 0.5s, ease-out 0.5s;
}
.home header:before {
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 51.44%);
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 575px) {
  .home header {
    height: 400px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home header {
    height: 500px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home header {
    height: 600px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home header {
    height: 700px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .home header {
    height: 800px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .home header {
    height: 1000px;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  }
}
@media only screen and (min-width: 1920px) {
  .home header {
    height: 1200px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  }
}

.headerCart {
  display: flex;
  justify-content: flex-end;
}
.headerCart-menu {
  border-radius: 0;
  left: initial !important;
  right: 0 !important;
}
.headerCart-buttoncontainer {
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
}
.headerCart-item:active a {
  color: #fff;
}
.headerCart-link {
  display: block;
  text-align: right;
}
.headerCart-buttonContainer {
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
}

#menu {
  padding: 0;
}
@media (max-width: 991px) {
  #menu {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1024;
    background: #16172B;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #menu {
    width: 80%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  #menu {
    width: 70%;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  #menu {
    width: 60%;
  }
}
@media only screen and (min-width: 1920px) {
  #menu {
    width: 50%;
  }
}

.navbar {
  padding: 0;
}
.navbar .navbar-nav {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
}
.navbar .navbar-nav .nav-item .nav-link {
  font-family: "Old Standard TT", Arial, sans-serif;
  color: white;
  font-weight: bold;
  font-size: 1.25rem;
}
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: #A93B2A;
}
.navbar .navbar-nav .nav-item .nav-link:focus, .navbar .navbar-nav .nav-item .nav-link:active {
  color: #d87161;
}
@media (max-width: 991px) {
  .navbar .navbar-nav .nav-item {
    margin: 0.3rem 0;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    font-size: 1.625rem;
  }
}

.navbar-light .navbar-toggler {
  color: white;
  border: none;
  background: white;
}

.rs {
  display: flex;
  flex-wrap: wrap;
}
.rs-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  margin: 0;
}
.rs-item {
  display: flex;
  margin: 0 1rem 1rem;
}
.rs-link {
  display: inline-flex;
}
.rs-link:active .rs-icon, .rs-link:focus .rs-icon, .rs-link:hover .rs-icon {
  fill: #953425;
}
.rs-icon {
  width: 30px;
  height: 30px;
  transition: transform 0.5s, fill 0.5s;
  fill: #A93B2A;
  vertical-align: text-top;
}

.vcard {
  /*.nickname {
      display: none;
  }*/
}
.vcard .org {
  text-transform: uppercase;
  display: none;
}
.vcard .adr .locality {
  text-transform: uppercase;
}
.vcard .adr .country-name {
  display: none;
}
.vcard .tel {
  display: none;
}
.vcard abbr {
  border: 0;
}
.vcard .telnumber a {
  color: #fff;
  text-decoration: none;
}
.vcard .faxnumber {
  display: none;
}
.vcard .email_container {
  margin: 20px 0;
}
.vcard .email_name {
  display: none;
}
.vcard a.email {
  text-decoration: underline;
  display: none;
}
.vcard a.email:hover {
  text-decoration: none;
}

footer {
  padding: 0 0 20px;
}
@media only screen and (min-width: 1920px) {
  footer .container {
    width: 1170px;
  }
}
footer a {
  color: white;
}
footer a:hover {
  color: #A93B2A;
}
footer a:focus, footer a:active {
  color: #d87161;
}
footer .footer-logo {
  text-align: center;
  margin: 0 auto 26px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  footer .footer-logo .img-fluid {
    height: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  footer .footer-logo .img-fluid {
    height: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  footer .footer-logo .img-fluid {
    height: 120px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  footer .footer-logo .img-fluid {
    height: 120px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  footer .footer-logo .img-fluid {
    height: 192px;
  }
}
@media only screen and (min-width: 1920px) {
  footer .footer-logo .img-fluid {
    height: 192px;
  }
}
footer .footer-infos {
  justify-content: space-between;
  align-items: top;
}
@media only screen and (max-width: 575px) {
  footer .footer-infos {
    justify-content: center;
  }
  footer .footer-infos .footer-contact,
footer .footer-infos .footer-resa,
footer .footer-infos .footer-social {
    flex-basis: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  footer .footer-infos {
    justify-content: center;
  }
  footer .footer-infos .footer-contact,
footer .footer-infos .footer-resa,
footer .footer-infos .footer-social {
    flex-basis: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  footer .footer-infos {
    justify-content: center;
  }
  footer .footer-infos .footer-contact,
footer .footer-infos .footer-resa,
footer .footer-infos .footer-social {
    flex-basis: 50%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  footer .footer-infos {
    justify-content: center;
  }
  footer .footer-infos .footer-contact,
footer .footer-infos .footer-resa,
footer .footer-infos .footer-social {
    flex-basis: 50%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  footer .footer-infos .footer-contact,
footer .footer-infos .footer-resa,
footer .footer-infos .footer-social {
    flex-basis: 30%;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  footer .footer-infos .footer-contact,
footer .footer-infos .footer-resa,
footer .footer-infos .footer-social {
    flex-basis: 27%;
  }
}
@media only screen and (min-width: 1920px) {
  footer .footer-infos .footer-contact,
footer .footer-infos .footer-resa,
footer .footer-infos .footer-social {
    flex-basis: 27%;
  }
}
footer .footer-infos .footer-resa a {
  white-space: nowrap;
}
@media (min-width: 1200px) {
  footer .footer-infos .footer-resa {
    text-align: center;
  }
}
footer .footer-infos .rs-list {
  display: flex;
  justify-content: space-around;
  font-size: 150%;
}

.footer-horaires {
  margin-top: 3rem;
  justify-content: space-between;
}
.footer-horaires .horaires-resto {
  flex-basis: 50%;
}
.footer-horaires .horaires-resto h2 {
  height: 54px;
  text-indent: -9999px;
  background: url(/images/svg/logo-resto-pizzeria.svg) no-repeat center left transparent;
  background-size: contain;
}
@media only screen and (max-width: 767px) {
  .footer-horaires .horaires-resto {
    flex-basis: 100%;
  }
}
.footer-horaires .horaires-echoppe h2 {
  height: 40px;
  text-indent: -9999px;
  background: url(/images/logo-l-echoppe.png) no-repeat center left transparent;
  background-size: contain;
}
@media only screen and (max-width: 767px) {
  .footer-horaires .horaires-echoppe {
    flex-basis: 100%;
  }
}

#footer-navigation .footer-navigationList {
  list-style: none;
  margin: 1.5rem 0;
  font-size: 1.125rem;
}
#footer-navigation .footer-navigationList li {
  display: inline;
}

.footer-signature {
  font-size: 0.875rem;
}
.footer-signature a {
  color: white;
}
.footer-signature a:hover {
  color: #A93B2A;
}
.footer-signature a:focus, .footer-signature a:active {
  color: #d87161;
}
@media only screen and (max-width: 575px) {
  .footer-signature .text-right {
    text-align: left !important;
  }
}

/* BOTTOM BAR MOBILE */
.bottomBar {
  background: #000;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .bottomBar {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.bottomBar .topBar-link {
  border: 0;
  background: transparent;
  color: #A93B2A;
  font-size: 150%;
}

#content {
  /*.container {
      @include respond-to($semi-desktop1){
          width: 80%;
      }@include respond-to($semi-desktop2){
          width: 60%;
      }@include respond-to($wide-desktop){
          width: 1170px;
      }
  }*/
}
#content .card-intro {
  background: url(/images/bistrot-d-angel_le-resto.jpg) no-repeat center center transparent;
  background-size: cover;
  margin-bottom: 2rem;
}
#content .card-intro .card-intro-title {
  height: 170px;
  text-indent: -9999px;
  background: url(/images/svg/txt_le-resto.svg) no-repeat center center transparent;
  background-size: contain;
}
@media only screen and (max-width: 767px) {
  #content .card-intro .card-intro-title {
    height: 110px;
  }
}
#content .card-intro .card-intro-txt {
  margin: 0 auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  #content .card-intro .card-intro-txt {
    width: 90%;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  #content .card-intro .card-intro-txt {
    width: 90%;
  }
}
@media only screen and (min-width: 1920px) {
  #content .card-intro .card-intro-txt {
    width: 90%;
  }
}