.headerCart {
    display: flex;
    justify-content: flex-end;

    &-menu {
        border-radius: 0;
        left: initial !important;
        right: 0 !important;
    }

    &-buttoncontainer {
        padding-left: 15px;
        padding-right: 15px;
        justify-content: center;
    }

    &-item {
        &:active a {
            color: #fff;
        }
    }

    &-link {
        display: block;
        text-align: right;
    }

    &-buttonContainer {
        padding: .5rem 1.5rem;
        display: flex;
        justify-content: center;
    }
}
