#menu {
    padding: 0;
    @media (max-width: 991px){
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1024;
        background: $secondary;
    }@include respond-to($tablet-landscape){
        width: 80%;
    }@include respond-to($semi-desktop1){
        width: 70%;
    }@include respond-to($semi-desktop2){
        width: 60%;
    }@include respond-to($wide-desktop){
        width: 50%;
    }
}

.navbar {
    padding: 0;
    .navbar-nav {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        width: 100%;
        .nav-item {
            .nav-link {
                font-family: $ffTitle;
                color: $colorText;
                font-weight: bold;
                font-size: rem(20px);
                &:hover {
                    color:$primary;
                }
                &:focus, &:active {
                    color: $primaryLight;
                }
            }
            @media (max-width: 991px){
                margin: .3rem 0;
                .nav-link {
                    font-size: rem(26px);    
                }
            }
        }
    }
}

.navbar-light .navbar-toggler {
    color: $colorText;
    border: none;
    background: $colorText;
}
