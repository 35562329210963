$fzText: 24px;
$fzH1: 50px;
$fzH2: 32px;
$fzH3: 28px;
$fzH4: 24px;
$fzH5: 20px;
$fzH6: 16px;

$ffTitle: "Old Standard TT", Arial, sans-serif;
$ffText: "Roboto", Arial, sans-serif;
$ffItalic: "Roboto", Arial, sans-serif;
$ffBold: "Roboto", Arial, sans-serif;

$widthContainer: 1440px;

%txt-xs {
    font-size: 16px;
    font-family: $ffTitle;
}

// Body
$body-bg: #000;

$default: #fff;
$defaultLight: lighten( $default, 20% );
$defaultDark: darken( $default, 20% );

$primary: #A93B2A;
$primaryLight: lighten( $primary, 20% );
$primaryDark: darken( $primary, 20% );

$secondary: #16172B;
$secondaryLight: lighten( $secondary, 20% );
$secondaryDark: darken( $secondary, 20% );

$bgcHeader: #000;
$bgcFooter: #000;

$colorTitle: white;
$colorText: white;
$colorLink: $primary;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$light: #f8f9fa;
$dark: #343a40;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$danger: $red;


// Divers

$swiperThemeColor: $primary;

$transition: all, .5s, ease-out .5s;

$mobile: '(max-width: 767px)';
$phone-portrait: '(max-width: 575px)';
$phone-landscape: '(min-width: 576px) and (max-width: 767px)';
$tablet-portrait: '(min-width: 768px) and (max-width: 991px)';
$tablet-landscape: '(min-width: 992px) and (max-width: 1199px)';
$large-desktop: '(min-width: 1200px) and (max-width: 1919px)';
$semi-desktop1: '(min-width: 1200px) and (max-width: 1439px)';
$semi-desktop2: '(min-width: 1440px) and (max-width: 1919px)';
$wide-desktop: '(min-width: 1920px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)';
