/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-v29-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('/fonts/roboto-v29-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-v29-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/roboto-v29-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-v29-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('/fonts/roboto-v29-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-v29-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* old-standard-tt-regular - latin */
@font-face {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/old-standard-tt-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/old-standard-tt-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* old-standard-tt-700 - latin */
@font-face {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/old-standard-tt-v17-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/old-standard-tt-v17-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot?sa7rsq');
  src:  url('/fonts/icomoon.eot?sa7rsq#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?sa7rsq') format('truetype'),
    url('/fonts/icomoon.woff?sa7rsq') format('woff'),
    url('/fonts/icomoon.svg?sa7rsq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock:before {
  content: "\e900";
}
.icon-phone:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-beer:before {
  content: "\e904";
}
.icon-coffee-shop-restaurant:before {
  content: "\e905";
}
.icon-pizza:before {
  content: "\e906";
}
.icon-tripadvisor:before {
  content: "\e907";
}
.icon-chevron-right:before {
  content: "\e908";
}
.icon-chevron-down:before {
  content: "\e909";
}

