.btn {  
    font-family: $ffTitle;
    font-size: rem(24px);
    font-weight: 400;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    white-space: normal; 
    transition: $transition;
    &:hover,
    &:active,
    &.active { transition: $transition; }
    &:focus,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        outline: 0;
        transition: $transition;
    }
}

.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
    text-shadow: none;
}
.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
    box-shadow: none;
}

.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
    text-shadow: none;
}


.btn-link, .btn-primary {
    background: transparent;
    border: 0;
    border-bottom: 1px dashed $colorText; 
    color: $colorText;
    box-shadow: none;

    &:hover,
    &:focus {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $primary; 
        color: $primary;
        box-shadow: none;
    }
    &:active,
    &.active {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $primaryDark; 
        color: $primaryDark;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $primaryLight; 
        color: $primaryLight;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        background: transparent;
        border-bottom: 1px dashed $primaryDark; 
        box-shadow: none;
    }
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
        box-shadow: none;
    }
}


.btn-secondary {
    background: transparent;
    border: 0;
    border-bottom: 1px dashed $colorText; 
    color: $colorText;
    box-shadow: none;

    &:hover,
    &:focus {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $secondary; 
        color: $secondary;
        box-shadow: none;
    }
    &:active,
    &.active {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $secondaryDark; 
        color: $secondaryDark;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $secondaryLight; 
        color: $secondaryLight;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        color: $secondaryLight;
        background: transparent;
        border-bottom: 1px dashed $secondaryDark; 
        box-shadow: none;
    }
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
        box-shadow: none;
    }
}

.btn-success {
    background: transparent;
    border: 0;
    border-bottom: 1px dashed $green; 
    color: $green;
    box-shadow: none;

    &:hover,
    &:focus {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $green; 
        color: $green;
        box-shadow: none;
    }
    &:active,
    &.active {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $green; 
        color: $green;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $secondaryLight; 
        color: $secondaryLight;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        color: $secondaryLight;
        background: transparent;
        border-bottom: 1px dashed $secondaryDark; 
        box-shadow: none;
    }
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
        box-shadow: none;
    }
}

.btn-info {
    background: transparent;
    border: 0;
    border-bottom: 1px dashed $blue; 
    color: $blue;
    box-shadow: none;

    &:hover,
    &:focus {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $blue; 
        color: $blue;
        box-shadow: none;
    }
    &:active,
    &.active {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $blue; 
        color: $blue;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $secondaryLight; 
        color: $secondaryLight;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        color: $secondaryLight;
        background: transparent;
        border-bottom: 1px dashed $secondaryDark; 
        box-shadow: none;
    }
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
        box-shadow: none;
    }
}

.btn-warning {
    background: transparent;
    border: 0;
    border-bottom: 1px dashed $orange; 
    color: $orange;
    box-shadow: none;

    &:hover,
    &:focus {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $orange; 
        color: $orange;
        box-shadow: none;
    }
    &:active,
    &.active {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $orange; 
        color: $orange;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $secondaryLight; 
        color: $secondaryLight;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        color: $secondaryLight;
        background: transparent;
        border-bottom: 1px dashed $secondaryDark; 
        box-shadow: none;
    }
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
        box-shadow: none;
    }
}

.btn-danger {
    background: transparent;
    border: 0;
    border-bottom: 1px dashed $red; 
    color: $red;
    box-shadow: none;

    &:hover,
    &:focus {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $red; 
        color: $red;
        box-shadow: none;
    }
    &:active,
    &.active {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $red; 
        color: $red;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: transparent;
        border: 0;
        border-bottom: 1px dashed $secondaryLight; 
        color: $secondaryLight;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        color: $secondaryLight;
        background: transparent;
        border-bottom: 1px dashed $secondaryDark; 
        box-shadow: none;
    }
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
        box-shadow: none;
    }
}

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}