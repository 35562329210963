@mixin respond-to($media) {
    @media only screen and #{$media} {
        @content;
    }
}

@mixin colAuto($pd: 15px) {
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;

    @if $pd {
        padding-left: $pd;
        padding-right: $pd;
    }
}

@mixin colSize($size, $pd: 15px) {
    flex: 0 0 auto;
    width: $size;
    max-width: 100%;

    @if $pd {
        padding-left: $pd;
        padding-right: $pd;
    }
}

@mixin bouton($hover: true, $color: $primary) {
    color: color-yiq($color);
    text-decoration: none;
    padding: .5em 1em;
    border-radius: 0;
    border-width: 0;
    display: inline-block;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s;
    outline: none;
    background-color: $color;
    font-size: rem($fzText);

    @if $hover {
        &:hover, &:focus, &:active {
            @include hoverBouton($color);
        }
    }
}

@mixin hoverBouton($color: $primary) {
    $color: rgba($color, .4);
    color: color-yiq($color);
    background-color: $color;
    text-decoration: none;
    cursor: pointer;
    box-shadow: none !important;
}

@mixin boutonOutline($hover: true, $color: $primary) {
    color: $color;
    text-decoration: none;
    padding: .5em 1em;
    border-radius: 0;
    border: 1px solid $color;
    display: inline-block;
    text-align: center;
    transition: background-color .2s, color .2s, border-color .2s;
    outline: none;
    background-color: transparent;
    font-size: rem($fzText);
}

@mixin hoverBoutonOutline($color: $primary) {
    color: color-yiq($color);
    background: $color;
    border-color: $color;
    text-decoration: none;
    cursor: pointer;
    box-shadow: none !important;
}

/* img-responsive */
%img-fluid {
    max-width: 100%;
    height: auto;
}