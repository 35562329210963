html {
    font-size: $html-font-size;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    @media (max-width: 991px) {
        font-size: rem($html-font-size * .8);
    }
}

body {
    margin: 0;
    padding: 0;
    text-align: left;
    background: $body-bg;
    font-family: $ffText;
    color: $colorText;
    font-size: rem($fzText);
    --swiper-theme-color: #{$swiperThemeColor};
    
    --swiper-navigation-size: 1.5rem;
    --swiper-theme-color: #{$primary};
    --swiper-pagination-bullet-inactive-color: #{$secondary};
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-width: 6rem;
    --swiper-pagination-bullet-height: 6px;
    --swiper-pagination-bullet-horizontal-gap: .6rem;
    @include respond-to($mobile){
        --swiper-pagination-bullet-width: 4rem;    
    }
    
    
}

.hidden {
    display: none;
}

@media (min-width: $widthContainer) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: $widthContainer;
    }
}

h1, h2, h3, h4, h5, h6, .title, .subtitle {
    margin: rem(20px) 0;
    padding: 0;
    font-weight: 700;
    font-family: $ffTitle;
    color: $colorTitle;
}

$listStyles: ("h1": $fzH1, "h2": $fzH2, "h3": $fzH3, "h4": $fzH4, "h5": $fzH5, "h6": $fzH6, ".title": $fzH1, ".subtitle": $fzH4);

@each $element, $size in $listStyles {
    #{$element} {
        font-size: rem($size);
    }
}

/*input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="week"],
input[type="month"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
optgroup,
select,
textarea,
.form-control {
  border: 1px solid gainsboro;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  background-color: #fff;
}

.form-control:focus {
    border-color: rgba($primary, .25);
    box-shadow: 0 0 0 0.2rem rgba($primary, .25);
}*/

ul, ol {
    margin: 10px;
    padding: 0;
    list-style-position: inside;
}

a {
    transition: $transition;
    color: $colorLink;

    &:hover {
        text-decoration: none;
        color: $colorLink;
    }

    &:focus {
        outline: 0;
    }

    img {
        border: 0;
    }

    // &[href$=".pdf"] {
    //     padding: 10px 0 10px 30px;
    //     background: url(/img/pdf.png) no-repeat 0 50% transparent;
    // }
}

blockquote {
    margin: 30px 0;
    padding: 5px 15px;
    border-left: 4px solid #eee;
    font-style: italic;
}

em {
    font-style: italic;
}

strong {
    font-weight: 700;
}

button {
    transition: $transition;
}

.overflow {
    overflow: hidden;
}

.scrollToTop {
    position: fixed;
    bottom: 50px;
    right: 5px;
    cursor: pointer;
    z-index: 1000000;
    display: none;

    &-icon {
        fill: $primary;
        width: 25px;
        height: 25px;
    }
    
    @media(min-width: 768px){
        bottom: 20px;
        right: 20px;
        &-icon {
            width: 35px;
            height: 35px;
        }
    }
}

ol.breadcrumb {
    margin: 20px 0;
    padding: 0;
    background: transparent;
    font-size: rem(15px);
}

/*
 * Toggler menu mobile */
.toggler-mobile {
    position: absolute;
    
    z-index: 1024;
    
    @include respond-to($mobile){
        top: -20px;
        left: 10px;
    }@include respond-to($tablet-portrait){
        top: -20px;
        left: 50%;
        margin-left: -25px;    
    }
    @media (min-width: 992px){
        display: none;
    }
    width: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0; 
    
    .line {
      fill: none;
      stroke: white;
      stroke-width: 6;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .line1 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    .line2 {
      stroke-dasharray: 60 60;
      stroke-width: 6;
    }
    .line3 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    &.opened {
        left: inherit;
        right: 10px;
    }
    &.opened .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
    &.opened .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 6;
    }
    &.opened .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
}


/* #CONTENT
================================================== */
.bg__lines {
    background: url(/images/svg/lines.svg) no-repeat top center transparent;
    background-size: cover;
}


.card-intro {
    position: relative;
    border-radius: 8px;
    padding: 2rem;
    overflow: hidden;
    &:before {
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        background: linear-gradient(180deg, rgba(169, 59, 42, 0.5) 0%, $secondary 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .card-intro-content {
        position: relative;
        z-index: 1;
        font-size: rem(20px);
    }
}
.home {
    .card-intro {
        &:before {
            background: linear-gradient(0deg, rgba(169, 59, 42, 0.2) 30%, $secondary 100%);
        }
    }
}


#content {
    padding-bottom: 20px;
}
.home {
    #content {
        padding-bottom: 0;
    }
}

#horaires {
    color: $body-bg;
    p {
        font-size: rem(20px);    
    }
    h1, h2, h3, h4, h5, h6 {
        color: $body-bg;
        font-size: rem(30px);
    }
}

.fancybox-slide--current #hidden-content-b {
    transform: translateY(0);
}

#page { 
    img {
        @extend %img-fluid;
    }
}
/* ----- NOTIFICATIONS -----*/

.result_error {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #e57c7c;
}

.result_success {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #78ca71;
}

#flashMessage {
    background: #fff;
    color: #337ab7;
    line-height: 40px;
    text-align: center;
}

// Connexion
#tableau_login { overflow:hidden; }

#compte { margin: 20px auto}

#UserSiteLoginForm div, #UserAddUserForm div { margin: 0 0 10px; }

.error-message { color: #a94442}

// Contact
.accept {
    display: inline;
}

.control-label label {
    font-family: $ffTitle;
}

.error {
    .form-control {
        border-color: $danger;
    }
    .control-label {
        color: $danger;
    }
}

.mandatory {
    height: 0.1px;
    width: 0;
    border: 0 !important;
    padding: 0 !important;
}

.mentionRGPD, .champsRequis {
    font-size: rem(14px);
}

#map {
    background: $gray-200;
    max-width: 100%;
    height: 500px;
    color: #000;
    .googlemaps-canvas {
        max-width: 100%;    
    }
}

.form-control {
    border-radius: 8px;
    background: transparent;
    color: $colorText;
    &:focus {
        border-color: $primaryLight;  
        box-shadow: 0 0 0 0.2rem rgba(159, 69, 42, 0.5);
    }
}

/* ----- DEMO ASSETS ----- */

.bs-component {
    .modal {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        width: 90%;
    }
}

.modal-title {
    margin-top: 0;
}

// pagination
.pagination {
    justify-content: center;
}

/*.page {
    &-item {
        &.active {
            background-color: $primary;
            .page-link {
                background-color: $primary;
                border-color: $primary;
            }
        }
    }
    &-link {
        &, &:hover {
            color: $primary;
        }
        &:focus {
            box-shadow: none;
        }
    }
}*/

// Surclasses les boutons Bootstrap
// $btns:
// "primary" $primary,
// "secondary" $secondary,
// "success" $green,
// "danger" $red,
// "warning" $yellow,
// "info" $cyan,
// "light" $light,
// "dark" $dark;
//
// @each $modifier, $colorBtn in $btns {
//     .btn-#{$modifier} {
//         @include bouton($hover: false, $color: $colorBtn);
//
//         &:not(:disabled):not(.disabled) {
//             &:hover, &:focus, &:active {
//                 @include hoverBouton($color: $colorBtn);
//             }
//         }
//     }
//
//     .btn-outline-#{$modifier} {
//         @include boutonOutline($hover: false, $color: $colorBtn);
//
//         &:not(:disabled):not(.disabled) {
//             &:hover, &:focus, &:active {
//                 @include hoverBoutonOutline($color: $colorBtn);
//             }
//         }
//     }
// }

